import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const holidayListSlice = createSlice({
  name: "holidayList",
  initialState,
  reducers: {
    setHolidayListData: (state, action) => {
      state.setHolidayListData = action.payload;
    },
    // setProjectOptions: (state, action) => {
    //   state.setProjectOptions = action.payload;
    // },
    // setProjectPagination: (state, action) => {
    //   state.setProjectPagination = action.payload;
    // },
  },
});

export const { setHolidayListData } = holidayListSlice.actions;

export default holidayListSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const alternativeWorkingDayListSlice = createSlice({
  name: "alternativeWorkingDayList",
  initialState,
  reducers: {
    setAlternativeWorkingDayListData: (state, action) => {
      state.setAlternativeWorkingDayListData = action.payload;
    },
  },
});

export const { setAlternativeWorkingDayListData } =
  alternativeWorkingDayListSlice.actions;

export default alternativeWorkingDayListSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const leaveStatusSlice = createSlice({
  name: "leaveStatus",
  initialState,
  reducers: {
    setLeaveStatusData: (state, action) => {
      state.setLeaveStatusData = action.payload;
    },
    setLeaveStatusPaginationData: (state, action) => {
      state.setLeaveStatusPaginationData = action.payload;
    },
    setLeaveStatusOptions: (state, action) => {
      state.setLeaveStatusOptions = action.payload;
    },
  },
});

export const {
  setLeaveStatusData,
  setLeaveStatusPaginationData,
  setLeaveStatusOptions,
} = leaveStatusSlice.actions;

export default leaveStatusSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const leaveTypeSlice = createSlice({
  name: "leaveType",
  initialState,
  reducers: {
    leaveType: (state, action) => {
      state.leaveType = action.payload;
    },
    setLeaveTypeData: (state, action) => {
      state.setLeaveTypeData = action.payload;
    },
    setLeaveTypePaginationData: (state, action) => {
      state.setLeaveTypePaginationData = action.payload;
    },
    setLeaveTypeOptions: (state, action) => {
      state.setLeaveTypeOptions = action.payload;
    },
  },
});

export const {
  leaveType,
  setLeaveTypeData,
  setLeaveTypePaginationData,
  setLeaveTypeOptions,
} = leaveTypeSlice.actions;

export default leaveTypeSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const dailyTaskSlice = createSlice({
  name: "dailyTask",
  initialState,
  reducers: {
    dailyTask: (state, action) => {
      state.dailyTask = action.payload;
    },
    taskPagination: (state, action) => {
      state.taskPagination = action.payload;
    },
    calculateTime: (state, action) => {
      state.calculateTime = action.payload;
    },
  },
});

export const { dailyTask, taskPagination, calculateTime } =
  dailyTaskSlice.actions;

export default dailyTaskSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const salarySlipSlice = createSlice({
  name: "salarySlip",
  initialState,
  reducers: {
    setSalarySlipData: (state, action) => {
      state.setSalarySlipData = action.payload;
    },
    setSalarySlipPagination: (state, action) => {
      state.setSalarySlipPagination = action.payload;
    },
  },
});

export const { setSalarySlipData, setSalarySlipPagination } =
  salarySlipSlice.actions;

export default salarySlipSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRoleData: (state, action) => {
      state.setRoleData = action.payload;
    },
    setRoleOptions: (state, action) => {
      state.setRoleOptions = action.payload;
    },
  },
});

export const { setRoleData, setRoleOptions } = roleSlice.actions;

export default roleSlice.reducer;

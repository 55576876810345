import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const projectCategorySlice = createSlice({
  name: "projectCategory",
  initialState,
  reducers: {
    setProjectCategoryData: (state, action) => {
      state.setProjectCategoryData = action.payload;
    },
    setProjectCategoryOptions: (state, action) => {
      state.setProjectCategoryOptions = action.payload;
    },
  },
});

export const { setProjectCategoryData, setProjectCategoryOptions } =
  projectCategorySlice.actions;

export default projectCategorySlice.reducer;

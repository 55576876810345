import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const compensationTimeListSlice = createSlice({
  name: "compensationTime",
  initialState,
  reducers: {
    setCompensationTimeListData: (state, action) => {
      state.setCompensationTimeListData = action.payload;
    },
    setCompensationTimePagination: (state, action) => {
      state.setCompensationTimePagination = action.payload;
    },
  },
});

export const { setCompensationTimeListData, setCompensationTimePagination } =
  compensationTimeListSlice.actions;

export default compensationTimeListSlice.reducer;

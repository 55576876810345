import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const assignedProjectsSlice = createSlice({
  name: "assignedProjects",
  initialState,
  reducers: {
    assignedProjects: (state, action) => {
      state.assignedProjects = action.payload;
    },
    totalTime: (state, action) => {
      state.totalTime = action.payload;
    },
    assignProjectList: (state, action) => {
      state.assignProjectList = action.payload;
    },
    setAssignedProjectListForAdmin: (state, action) => {
      state.setAssignedProjectListForAdmin = action.payload;
    },
    setAssignedProjectListFormatForAdmin: (state, action) => {
      state.setAssignedProjectListFormatForAdmin = action.payload;
    },
    setAssignProjectOptions: (state, action) => {
      state.setAssignProjectOptions = action.payload;
    },
  },
});

export const {
  assignedProjects,
  totalTime,
  assignProjectList,
  setAssignedProjectListForAdmin,
  setAssignedProjectListFormatForAdmin,
  setAssignProjectOptions,
} = assignedProjectsSlice.actions;

export default assignedProjectsSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const designationSlice = createSlice({
  name: "designation",
  initialState,
  reducers: {
    setDesignationData: (state, action) => {
      state.setDesignationData = action.payload;
    },
    setDesignationOptions: (state, action) => {
      state.setDesignationOptions = action.payload;
    },
  },
});

export const { setDesignationData, setDesignationOptions } =
  designationSlice.actions;

export default designationSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjectData: (state, action) => {
      state.setProjectData = action.payload;
    },
    setProjectOptions: (state, action) => {
      state.setProjectOptions = action.payload;
    },
    setProjectPagination: (state, action) => {
      state.setProjectPagination = action.payload;
    },
  },
});

export const { setProjectData, setProjectOptions, setProjectPagination } =
  projectsSlice.actions;

export default projectsSlice.reducer;

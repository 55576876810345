import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  monthlyReport:[]
};

export const monthlyReportSlice = createSlice({
  name: "monthlyReport",
  initialState,
  reducers: {
    setMonthlyReport: (state, action) => {
      state.monthlyReport = action.payload;
    },
    // taskPagination: (state, action) => {
    //   state.taskPagination = action.payload;
    // },
    // calculateTime: (state, action) => {
    //   state.calculateTime = action.payload;
    // },
  },
});

export const {  setMonthlyReport } = monthlyReportSlice.actions;

export default monthlyReportSlice.reducer;

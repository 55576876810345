import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const techSkillSlice = createSlice({
  name: "techSkill",
  initialState,
  reducers: {
    setTechSkillData: (state, action) => {
      state.setTechSkillData = action.payload;
    },
    setTechSkillsOptions: (state, action) => {
      state.setTechSkillsOptions = action.payload;
    },
  },
});

export const { setTechSkillData, setTechSkillsOptions } =
  techSkillSlice.actions;

export default techSkillSlice.reducer;

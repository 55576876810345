import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    employee: (state, action) => {
      state.employee = action.payload;
    },
    setEmployeeOptions: (state, action) => {
      state.setEmployeeOptions = action.payload;
    },
    setSelectedEmployeeData: (state, action) => {
      state.setSelectedEmployeeData = action.payload;
    },
    setActiveEmployeeData: (state, action) => {
      state.setActiveEmployeeData = action.payload;
    },
    setInActiveEmployeeData: (state, action) => {
      state.setInActiveEmployeeData = action.payload;
    },
  },
});

export const {
  employee,
  setEmployeeOptions,
  setSelectedEmployeeData,
  setActiveEmployeeData,
  setInActiveEmployeeData,
} = employeeSlice.actions;

export default employeeSlice.reducer;

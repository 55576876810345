import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const receiverEmailSlice = createSlice({
  name: "receiverEmail",
  initialState,
  reducers: {
    setReceiverEmailData: (state, action) => {
      state.setReceiverEmailData = action.payload;
    },
    setReceiverEmailOptions: (state, action) => {
      state.setReceiverEmailOptions = action.payload;
    },
  },
});

export const { setReceiverEmailData, setReceiverEmailOptions } =
  receiverEmailSlice.actions;

export default receiverEmailSlice.reducer;

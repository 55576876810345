import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const engineerSlice = createSlice({
  name: "engineer",
  initialState,
  reducers: {
    engineer: (state, action) => {
      state.engineer = action.payload;
    },
    setEngineerOptions: (state, action) => {
      state.setEngineerOptions = action.payload;
    },
    setSelectedEngineerData: (state, action) => {
      state.setSelectedEngineerData = action.payload;
    },
    setActiveEngineerData: (state, action) => {
      state.setActiveEngineerData = action.payload;
    },
    setInActiveEngineerData: (state, action) => {
      state.setInActiveEngineerData = action.payload;
    },
  },
});

export const {
  engineer,
  setEngineerOptions,
  setSelectedEngineerData,
  setActiveEngineerData,
  setInActiveEngineerData,
} = engineerSlice.actions;

export default engineerSlice.reducer;
